<template>
  <div class="container-fluid">
    <div class="row mb-1">
      <div class="d-flex flex-row">
        <div class="event-type-task d-flex justify-content-center align-items-center rounded-start">
          <div class="event-task"></div>
        </div>
        <div class="container-fluid bg-white text-dark line rounded-end">
          <div class="d-flex flex-row justify-content-between pt-2">
            <div class="d-flex flex-row">
              <div class="px-1"><span>14.10.2021</span></div>
              <div class="px-1"><span>13:45-13:50</span></div>
              <div class="px-1"><span>Исходящий звонок от: Иванов Иван, кому:89098534466. Статус: состоялся</span></div>
            </div>
          </div>
          <div class="d-flex flex-row px-1 pb-2">
            <span class="fw-bolder">Входящий звонок 10:44 </span>
            <span class="px-3">Прослушать</span>
            <div>
            <a href="" @click.prevent><img src="../assets/img/call-listen.svg" width="12" height="12"></a>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.event-task {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets/img/call-in.svg');
}
.event-type-task {
  background-color: #2D87DA;
  width: 24px;
}

.form-group {
  width: 100%;
  padding-right: 10px;
}
</style>
