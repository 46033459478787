<template>


  <div class="d-flex vh-100" style="height: calc(100vh - 150px) !important;">
    <div class="d-flex main flex-column"> <!-- vh-100 -->
      <div id="main-event-block" class="main-event-block p-4 pb-0 flex-grow-1 w-100">

        <!-- Message Block -->

        <event-in-call></event-in-call>
        <event-out-call></event-out-call>

        <separator-data></separator-data>

        <event-call></event-call>
        <event-out-mail></event-out-mail>
        <event-in-mail></event-in-mail>
        <event-out-sms></event-out-sms>
        <event-in-sms></event-in-sms>
        <event-dead-line></event-dead-line>
        <event-task></event-task>

        <separator-today></separator-today>

        <event-dead-line></event-dead-line>
        <event-task></event-task>

        <event-dead-line></event-dead-line>

        <event-dead-line></event-dead-line>

        <event-done v-for="item in database"
                    :key="item.id"
                    :date="item.date"
                    :time="item.time"
                    :status="item.status"
                    :text="item.text"
                    :name="item.name"
        ></event-done>


<!--
        <dynamic-event-link
            :type="this.eventType"
        ></dynamic-event-link>
        -->

        <!-- Message Block -->

      </div>

      <div class="rounded-top flex-grow-0 ms-2 me-3 pe-4 pb-1 pt-0">
        <div v-if="actionBlockType == 'chat'">
          <action-block @changeActionBlock="changeActionBlock"></action-block>
        </div>
        <div v-if="actionBlockType == 'task'">
          <tasks-block
              @changeActionBlock="changeActionBlock"
              @runTask="runTask"
          ></tasks-block>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import EventDone from "./EventDone";
import EventBlank from "./EventBlank";
import EventDeadLine from "./EventDeadLine";
import EventTask from "./EventTask";
import EventInCall from "./EventInCall";
import EventOutCall from "./EventOutCall";
import EventOutMail from "./EventOutMail";
import EventOutSms from "./EventOutSms";
import EventInSms from "./EventInSms";
import EventCall from "./EventCall";
import SeparatorToday from "./SeparatorToday";
import SeparatorData from "./SeparatorData";
import DynamicEventLink from "./DynamicEventLink";

import {db} from "../db";
import EventInMail from "./EventInMail";
import ActionBlock from "./ActionBlock";
import TasksBlock from "./TasksBlock";

export default {
  components: {
    EventDeadLine, EventDone, EventBlank, EventTask,
    EventInCall, EventOutCall, EventCall,
    EventOutMail, EventInMail,
    EventOutSms, EventInSms,
    SeparatorToday, SeparatorData,

    ActionBlock,
    TasksBlock,

    DynamicEventLink
  },
  data() {
    return {
      database: db,
      actionBlockType: 'task',
      eventType: 'event-block'
    }
  },
  methods: {
    async runTask(task) {
      // тест для динамического добавления компонентов...
      const item = {
        id: '0',
        status: 'done',
        time: '13:54',
        name: 'Иванов Иван',
        date: '14.10.2021',
        text: 'Новое Событие!!!'
      }

      this.database.push(item)

      setTimeout(() => {
        let container = document.querySelector("#main-event-block")
        container.scrollTop = container.scrollHeight
      })
    },
    changeActionBlock(block) {
      this.actionBlockType = block.block
    }
  },
}
</script>

<style scoped>
#main-event-block::-webkit-scrollbar {
  width: 7px;
  background-color: #E9EBEE;
}
#main-event-block::-webkit-scrollbar-thumb {
  background-color: #C0BAD1;
}
.main {
  background-color: #F4F5F7;
}
.main-event-block {
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #F4F5F7;
}


.left-block {
  min-width: 200px;
  background-color: #1F1736;
}

</style>
