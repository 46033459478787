<template>
  <div class="event-footer container-fluid bg-white text-dark rounded-top mt-2 py-2 h-auto overflow-hidden">
    <div class="row mb-1">
      <div class="d-flex flex-row">

        <div class="event-type-task d-flex justify-content-center align-items-center">
          <div>
            <a href="" @click.prevent><img src="../assets/img/chat-menu.svg" width="2" height="12"></a>
          </div>
        </div>

        <div class="container-fluid bg-white text-dark line">

          <div class="d-flex flex-row">

            <div id="myDropdown" class="dropdown">
              <div class="dropdown-content">
                <div class="dropdown-content-item">
                  <div class="">
                    <a href="#" @click.prevent="changeBlock('chat')">Чат<i class="dropdown-current-item"></i></a>
                  </div>
                </div>
                <div class="dropdown-content-item">
                  <a href="#" @click.prevent="changeBlock('note')">Примечание</a>
                </div>
                <div class="dropdown-content-item last-item">
                  <a href="#" @click.prevent="changeBlock('task')">Задача</a>
                </div>
              </div>
              <div id="myDropdownTriangle" class="dropdown-triangle">&nbsp;</div>
            </div>


            <div class="px-1 pt-1"><a class="chatButton" href="" @click.prevent="myFunction">Чат</a></div>
            <span class="px-1 pt-1">для</span>
            <div class="px-1 pt-1">
              <a class="" href="" @click.prevent>(Telegram)/Иван Иванов:</a>
            </div>

            <div class="col bg-white text-dark">
              <input type="text" class="form-control form-control-sm" aria-describedby="message" placeholder="Введите текст">
            </div>
            </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    changeBlock(event) {
      switch (event) {
        case 'chat': {
          this.$emit('changeActionBlock', {
            block: 'chat'
          })
          break
        }
        case 'task': {
          this.$emit('changeActionBlock', {
            block: 'task'
          })
          break
        }
      }
    },
  myFunction() {
    document.getElementById("myDropdown").classList.toggle("show");
    document.getElementById("myDropdownTriangle").classList.toggle("show");
  },
  showAction() {
    window.onclick = function(event) {
      if (!event.target.matches('.dropbtn')) {
        let dropdowns = document.getElementsByClassName("dropdown");
        let dropdownTriangle = document.getElementById("myDropdownTriangle");

        let openDropdown = dropdowns[0];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
          dropdownTriangle.classList.remove('show');
        }
      }
    }
  }
}
}
</script>

<style scoped>
.dropdown {
  display: none;
  position: absolute;
  background-color: white;
  width: 160px;
  height: auto;
  left: 250px;
  bottom: 55px;
}

.dropdown-triangle {
  display: none;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 0;
  border-color: white transparent transparent transparent;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content-item :hover {
  color: white;
  background-color: #0583FF;
  text-decoration: underline;

}
.show {display: block;}

.dropdown-current-item {
  float: right;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: 6px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets/img/check.svg');
}

</style>
