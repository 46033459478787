<template>
  <messages-block></messages-block>
  <!--  <b-card title="Классынй чат 🙌">
      <b-card-text>This is your second page.</b-card-text>
      <b-card-text>Chocolate sesame snaps pie carrot cake pastry pie lollipop muffin. Carrot cake dragée chupa chups jujubes. Macaroon liquorice cookie wafer tart marzipan bonbon. Gingerbread jelly-o dragée chocolate.</b-card-text>

  </b-card>-->
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'
import MessagesBlock from "@/components/MessagesBlock"

export default {
  components: {
    BCard,
    BCardText,
    MessagesBlock,
  },
  data() {
    return {
      my_data: ''
    }
  },
  mounted() {
    window.onclick = function(event) {
      if (!event.target.matches('.dropbtn') && !event.target.matches('.chatButton')) {
        let dropdowns = document.getElementsByClassName("dropdown");
        let dropdownTriangle = document.getElementById("myDropdownTriangle");

        let openDropdown = dropdowns[0];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
          dropdownTriangle.classList.remove('show');
        }
      }

      if (!event.target.matches('.connectionButton')) {
        let type_dropdowns = document.getElementsByClassName("task-type-dropdown");
        let type_dropdownTriangle = document.getElementById("taskTypeTriangle");

        let typeDropdown = type_dropdowns[0];
        if (typeof typeDropdown !== 'undefined' && typeDropdown.classList.contains('show')) {
          typeDropdown.classList.remove('show');
          type_dropdownTriangle.classList.remove('show');
        }
      }
    }
  },
}
</script>

<style>

</style>
