<template>
  <div class="event-footer container-fluid bg-white text-dark rounded-top mt-2 py-2 h-auto overflow-hidden">

    <div class="row mb-1">
      <div class="d-flex flex-row">
        <div class="container-fluid flex-column bg-white text-dark line">

          <div class="d-flex flex-row align-self-center">

            <div class="pe-2">
              <a href="" @click.prevent><img src="../assets/img/chat-menu.svg" width="2" height="12"></a>
            </div>

            <div class="dropdown">
              <div id="myDropdown" class="dropdown-content">
                <div class="dropdown-content-item">
                  <div class="">
                    <a href="#" @click.prevent="changeBlock('chat')">Чат</a>
                  </div>
                </div>
                <div class="dropdown-content-item">
                  <a href="#" @click.prevent="changeBlock('note')">Примечание</a>
                </div>
                <div class="dropdown-content-item">
                  <a href="#" @click.prevent="changeBlock('task')">Задача<i class="dropdown-current-item"></i></a>
                </div>
              </div>
              <div id="myDropdownTriangle" class="dropdown-triangle">&nbsp;</div>

            </div>

            <div class="px-1"><a class="chatButton" href="" @click.prevent="myFunction">Задача</a></div>


            <span class="px-1">на</span>
            <div class="bg-white text-dark line">
              <input type="text" class="form-control form-control-sm"
                     aria-describedby="message" placeholder="Сегодня">
            </div>

            <span class="px-1 ps-2">для</span>
            <div class="px-1">
              <a class="" href="" @click.prevent>Иван Иванов:</a>
            </div>


            <div id="taskType" class="task-type-dropdown">
              <div class="task-type-dropdown-content">
                <div class="task-type-dropdown-content-item">
                  <div class="">
                    <a href="#" @click.prevent="changeTask('Связаться', 'task-type-connect-item')">
                      <i class="task-type-item task-type-connect-item"></i>Связаться
                    </a>
                  </div>
                </div>
                <div class="task-type-dropdown-content-item">
                  <a href="#" @click.prevent="changeTask('Встретиться', 'task-type-meet-item')">
                    <i class="task-type-item task-type-meet-item"></i>Встретиться
                  </a>
                </div>
                <div class="task-type-dropdown-content-item">
                  <a href="#" @click.prevent="changeTask('Позвонить', 'task-type-call-item')">
                    <i class="task-type-item task-type-call-item"></i>Позвонить
                  </a>
                </div>
                <div class="task-type-dropdown-content-item">
                  <a href="#" @click.prevent="changeTask('Другое', 'task-type-other-item')">
                    <i class="task-type-item task-type-other-item"></i>Другое
                  </a>
                </div>
              </div>
              <div id="taskTypeTriangle" class="task-type-dropdown-triangle">&nbsp;</div>
            </div>

            <div class="ps-2">
              <a href="" @click.prevent><i
                  class="task-type-item me-1"
                  :class="this.currentTaskTypeIcon"
              ></i></a>
            </div>

            <span class="px-1 pe-2"><a class="connectionButton" href="" @click.prevent="showTaskTypes">{{ this.currentTaskType }}</a></span>



            <div class="bg-white text-dark line">
              <input type="text" class="form-control form-control-sm" aria-describedby="message" placeholder=""
              v-model="taskText">
            </div>

          </div>

          <div class="d-flex flex-row mt-2">
            <div class="">
              <button type="button" class="btn btn-primary btn-sm me-2" @click="runTask">Выполнить</button>
              <button type="button" class="btn btn-outline-primary btn-sm">Отменить</button>
            </div>

          </div>

        </div>

      </div>
    </div>
  </div>

</template>

<script>

export default {
  data() {
    return {
      currentTaskType: 'Связаться',
      currentTaskTypeIcon: 'task-type-connect-item',
      taskText: ''
    }
  },
  components: {

  },
  emits: ["changeActionBlock", "runTask"],
  methods: {
    runTask() {
      this.$emit('runTask', {
        task: {
          taskName: this.currentTaskType,
          taskText: this.taskText
        }
      })
    },
    changeTask(type, iconClass) {
      this.currentTaskType = type
      this.currentTaskTypeIcon = iconClass
    },
    changeBlock(event) {
      switch (event) {
        case 'chat': {
          this.$emit('changeActionBlock', {
            block: 'chat'
          })
          break
        }
        case 'task': {
          this.$emit('changeActionBlock', {
            block: 'task'
          })
          break
        }
      }
    },
    myFunction() {
      document.getElementById("myDropdown").classList.toggle("show");
      document.getElementById("myDropdownTriangle").classList.toggle("show");
    },
    showTaskTypes() {
      document.getElementById("taskType").classList.toggle("show");
      document.getElementById("taskTypeTriangle").classList.toggle("show");
    },
    showAction() {
      window.onclick = function(event) {
        if (!event.target.matches('.dropbtn')) {
          let dropdowns = document.getElementsByClassName("dropdown");
          let dropdownTriangle = document.getElementById("myDropdownTriangle");

          let openDropdown = dropdowns[0];
          if (openDropdown.classList.contains('show')) {
            openDropdown.classList.remove('show');
            dropdownTriangle.classList.remove('show');
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.dropdown {
  position: absolute;
  background-color: white;
}

.dropdown-content {
  position: absolute;
  display: none;
  width: 160px;
  height: auto;
  bottom: 10px;
  background-color: white;
}

.dropdown-triangle {
  display: none;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 0;
  border-color: white transparent transparent transparent;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content-item :hover {
  color: white;
  background-color: #0583FF;
  text-decoration: underline;

}

.dropdown-current-item {
  float: right;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: 6px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets/img/check.svg');
}




.task-type-dropdown {
  display: none;
  position: absolute;
  background-color: white;
  width: 160px;
  height: auto;
  left: 660px;
  bottom: 145px;
}

.task-type-dropdown-triangle {
  display: none;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 0;
  border-color: white transparent transparent transparent;
}

.task-type-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.task-type-dropdown-content-item :hover {
  color: white;
  background-color: #0583FF;
}


.task-type-item {
  float: left;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-top: 5px;
  margin-right: 10px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.task-type-connect-item {
  background-image: url('../assets/img/task-type-connect.svg');
}
.task-type-call-item {
  background-image: url('../assets/img/task-type-call.svg');
}
.task-type-meet-item {
  background-image: url('../assets/img/task-type-meet.svg');
}
.task-type-other-item {
  height: 2px;
  margin-top: 17px;
  background-image: url('../assets/img/task-type-other.svg');
}

.connectionButton {
  position: relative;
}

.show {display: block;}

</style>
