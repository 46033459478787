<template>
  <div class="container-fluid">
    <div class="row mb-1">
      <div class="d-flex flex-row">
        <div class="event-type-task d-flex justify-content-center align-items-center rounded-start">
          <div class="event-task"></div>
        </div>
        <div class="container-fluid bg-white text-dark line rounded-end">
          <div class="d-flex flex-row justify-content-between pt-2">
            <div class="d-flex flex-row">
              <div class="px-1"><span>14.10.2021</span></div>
              <div class="px-1"><span>13:45-13:50</span></div>
              <div class="px-1"><span>Исходящее письмо от: Иванов Иван, кому: Дмитрий</span></div>
            </div>
          </div>
          <div class="d-flex flex-row px-1">
            <span class="fw-bolder text-decoration-underline">Для Дмитрия, Тема письма</span>
          </div>
          <div class="px-1 fw-bolder pb-2"><span>Добрый день...</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.event-task {
  display: inline-block;
  width: 14px;
  height: 18px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets/img/mail-out.svg');
}
.event-type-task {
  background-color: #E79924;
  width: 24px;
}

.form-group {
  width: 100%;
  padding-right: 10px;
}
</style>
