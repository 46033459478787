export const db = [
    {
        id: '0',
        status: 'done',
        time: '13:54',
        name: 'Иванов Иван',
        date: '14.10.2021',
        text: 'Здесь будет запись о каком-то событии'
    },
    {
        id: '1',
        status: 'blank',
        time: '13:54',
        name: 'Иванов Иван',
        date: '15.10.2021',
        text: 'Здесь будет запись о каком-то событии. Здесь будет запись о каком-то событии'
    }
]
