<template>
  <div class="container-fluid">
    <div class="row mb-1">
      <div class="d-flex flex-row">
        <div class="event-type-task d-flex justify-content-center align-items-center rounded-start">
          <div class="event-task"></div>
        </div>
        <div class="container-fluid bg-white text-dark line rounded-end">
          <div class="d-flex flex-row justify-content-between pt-2">
            <div class="d-flex flex-row">
              <div class="px-1"><span>14.10.2021</span></div>
              <div class="px-1"><span>13:45-13:50</span></div>
              <div class="px-1"><span>Смс от: Иванов Иван, кому: Дмитрий на 999</span></div>
            </div>
          </div>
          <div class="d-flex flex-row px-1 pb-2">
            <span class="fw-bolder text-decoration-underline">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elementum vel egestas fermentum purus, ac mauris faucibus. Mattis dapibus molestie tellus augue morbi tristique faucibus. Et sed congue sit eget elit lorem aliquam. A ultricies sed odio enim vel, non tortor, netus dui.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.event-task {
  display: inline-block;
  width: 12px;
  height: 18px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets/img/sms-out.svg');
}
.event-type-task {
  background-color: #26B2BB;
  width: 24px;
}

.form-group {
  width: 100%;
  padding-right: 10px;
}
</style>
