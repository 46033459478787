<template>
  <div class="container-fluid">
    <div class="row mb-1">
      <div class="d-flex flex-row">
        <div class="event-type d-flex justify-content-center align-items-center rounded-start">
          <div class="done"></div>
        </div>
        <div class="container-fluid bg-white text-dark line rounded-end">
          <div class="d-flex flex-row">
            <div class="px-1"><span>{{ this.date}}</span></div>
            <div class="px-1"><span>{{ this.time}}</span></div>
            <div class="px-1"><span>{{ this.name}}</span></div>
          </div>
          <div class="d-flex flex-row px-1">
            <span class="">{{ this.text }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['date', 'status', 'time', 'name', 'text']
}
</script>

<style scoped>
.blank {
  display: inline-block;
  width: 12px;
  height: 15px;
}

.event-type {
  background-color: #DDE2E7;
  width: 24px;
}
</style>
