<template>
  <div class="container-fluid">
    <div class="row mb-1">
      <div class="d-flex flex-row">
        <div :class="classType">
          <div :class="className"></div>
        </div>
        <div class="container-fluid bg-white text-dark line rounded-end pt-2">
          <div class="d-flex flex-row">
            <div class="px-1"><span>{{ this.date}}</span></div>
            <div class="px-1"><span>{{ this.time}}</span></div>
            <div class="px-1"><span>{{ this.name}}</span></div>
          </div>
          <div class="d-flex flex-row px-1 pb-2">
            <span class="">{{ this.text }}</span>
          </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['date', 'status', 'time', 'name', 'text'],
  data() {
    return {
      className: this.status,
      classType: 'event-type-' + this.status + " d-flex justify-content-center align-items-center rounded-start"
    }
  }
}
</script>

<style scoped>
.done {
  display: inline-block;
  width: 12px;
  height: 15px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets/img/Vector3.svg');
}
.red {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets/img/Group2706.svg');
}
.blank {
  display: inline-block;
  width: 12px;
  height: 15px;
}

.event-type-done {
  background-color: #27AE60;
  width: 24px;
}
.event-type-blank {
  background-color: #DDE2E7;
  width: 24px;
}
 .event-type-red {
   background-color: #EA4646;
   width: 24px;
 }
</style>
