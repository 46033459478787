<template>
  <div class="separator">
    <span>14.10.2021</span>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.separator {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #DDE2E7;
  line-height: 0.1em;
  margin: 20px 0 20px;
  font-size: 12px;
}

.separator span {
  background:#fff;
  padding:0 10px 1px;
  border: #DDE2E7 1px solid;
  border-radius: 4px;
}

</style>
