<template>
  <div class="container-fluid">
    <div class="row mb-1">
      <div class="d-flex flex-row">
        <div class="event-type-task d-flex justify-content-center align-items-center rounded-start">
          <div class="event-task"></div>
        </div>
        <div class="container-fluid bg-white text-dark line rounded-end">
          <div class="d-flex flex-row justify-content-between">
            <div class="d-flex flex-row pt-2">
              <div class="px-1"><span>14.10.2021</span></div>
              <div class="px-1"><span>13:45-13:50</span></div>
              <div class="px-1"><span>Иван Иванов</span></div>
            </div>
            <div class="px-2">
              <span>ID11567</span>
            </div>
          </div>
          <div class="d-flex flex-row px-1">
            <span class="">Здесь будет описание какой-то задачи </span>
          </div>
          <form @submit.prevent>
            <div class="d-flex flex-row px-1 justify-content-between pb-2">
              <div class="form-group">
                <input type="text" class="form-control form-control-sm" aria-describedby="message" placeholder="Добавить результат">
              </div>
              <div>
                <button type="submit" class="btn btn-primary btn-sm">Выполнить</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.event-task {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets/img/event-task.svg');
}
.event-type-task {
  background-color: #27AE60;
  width: 24px;
}
.container-fluid {
  padding-left: 10px;
  padding-right: 10px;
}

.form-group {
  width: 100%;
  padding-right: 10px;
}
</style>
